import React, { Component } from 'react'

import styled from 'styled-components';

const DIVC = styled.div`
    padding: 2rem 0rem;
    margin: auto;
    text-align: center;
`

const Script = styled.h2`
    color: white;
    font-family: 'Amita', cursive;
    font-size: xx-large;
    animation: appear 3s linear;
    animation-fill-mode:forwards;

    @keyframes appear{
        0% {color: white;}
        100% {color: black;}
    }
`

export default class Success extends Component {
    render() {
        return (
            <>
            <DIVC>
                <Script>Thank you! Your message has been sent.</Script>
            </DIVC>
            </>
        )
    }
}
