import React, { useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from '../.';

import Form from '../components/ContactForm/Form';
import Success from '../components/ContactForm/Success';

import { graphql } from "gatsby"
import Img from "gatsby-image"

const Contact = ({data}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }

    return (
        <Layout>
            <SEO title="Contact"/>
            <br/>
            <Content.Container>
                <div align='center'>
                    <div style={{ maxWidth: `500px`}}>
                        <Img
                            title="Contact me"      
                            alt="Woman holding an envelope."
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </div>
                </div>
                <div>
                    {!isSubmitted ? 
                        <Form submitForm = {submitForm}/> :
                        <Success/>
                    }
                </div>
            </Content.Container>
        </Layout>
    )
}

export default Contact

export const query = graphql`  
  query {    
    file(relativePath: {eq: "Contact.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;